<template>
  <ManagementConnectionsConnectionMutations
    :current-id="currentId" 
  />
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useRoute } from "vue-router";
import ManagementConnectionsConnectionMutations from '@/components/ManagementConnectionsConnectionMutations';

export default defineComponent({
  components: {
    ManagementConnectionsConnectionMutations,
  },
  setup() {
    const route = useRoute();

    const currentId = computed(() => {
      return route.params.uid
    });

    return {
      currentId,
    }
  }
})
</script>