
import { defineComponent, onMounted, onBeforeMount } from "vue";
import ToolbarFilter from "@/components/toolbar/ToolbarFilter.vue";
import ToolbarActions from "@/components/toolbar/ToolbarActions.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import useComponentv2 from '@/composables/v2/useComponent';
import Table from '@/components/tables/v2/Table.vue';
import Modal from "@/components/modals/default/v2/Modal.vue";
import Icon from '@/components/icons/Icon.vue';

export default defineComponent({
  name: '',
  components: {
    ToolbarFilter,
    ToolbarActions,
    Table,
    Icon,
    Modal,
  },
  props: {
    currentId: String
  },
  setup (props, { emit }) {
    const { initComponent, componentData, componentRegisterId, componentLoading, updateComponent, submitButtonComponent, submitLoadingComponent, saveComponent, componentStatus, reloadComponent, test, reloadAmount } = useComponentv2();

    initComponent({
      componentName: "ManagementConnectionsConnectionMutationsTable", 
      displayMethod: "existing", /* new or existing */
      componentType: "table", /* form or table */
      componentTypeActions: { init: false, actions: [
        { type: "delete", label: "Delete", icon: { name: "Delete", color: 'danger' }, visibility: { state: false, maxSelected: null }, dispatch: { endpoint: "AUTOMATION_DELETE", reload: false, modal: { init: false, componentName: "", displayMethod: "", componentType: "" }, payloadConfig: { linesKey: "sale_lines", idKey: "return_id" } } },
      ]},
      dispatchActions: { init: "CONNECTION_MUTATIONS", save: "AUTOMATION_EDIT", params: { init: false, key: "", value: ""}},
      componentReload: true,
      componentRedirect: { init: false, data: "", path: ""},
      currentData: "allConnectionMutations",
      componentFilter: { init: true, get: "currentAutomationsFilter" },
      componentToolbar: { init: true, filter: true, actions: { init: true, title: "Mutations", menu: [] } },
      currentId: props.currentId
    });

    onMounted(() => {
        MenuComponent.reinitialization();
    })

    return {
      componentData,
      componentLoading,
      componentStatus,
      updateComponent,
      componentRegisterId,
      submitButtonComponent,
      submitLoadingComponent,
      saveComponent,
      reloadComponent,
      test,
      reloadAmount
    }
  }
});
